// transform-product-links.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'createProductLink'
})
export class CreateProductLinkPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, products: any ): SafeHtml {
    let replacedValue = value;
    products.forEach((product:any) => {
      // console.log(product);
      const productRegExp = new RegExp(product.name, 'gi');
      replacedValue = replacedValue.replace(
        productRegExp,
        `<a href="/${encodeURIComponent(product.path)}" [attr.aria-label]="Open ${product.name}" role="button" tabindex="0" >${product.name}</a>`);
    });

    return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
  }
}