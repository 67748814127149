import { Component, Input} from '@angular/core';
import { TextService } from 'src/services/text.service';
import { VERSION, AfterViewInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [ ]
})
export class FooterComponent  {

  constructor(public text: TextService, @Inject(DOCUMENT) private document: Document) {
    }

    getAllCookies(): { [key: string]: string } {
      const cookies: { [key: string]: string } = {};
      const all = this.document.cookie;

      if (all === "") {
        return cookies;
      }

      const list = all.split("; ");

      for (let i = 0; i < list.length; i++) {
        const cookie = list[i];
        const p = cookie.indexOf("=");
        const name = cookie.substring(0, p);
        const value = cookie.substring(p + 1);
        cookies[name] = decodeURIComponent(value);
      }

      return cookies;
    }
  }
