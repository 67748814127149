import { Component, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebpageService } from '../../services/web.service';
import { PreloadService } from '../../services/preload.service';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';


@Component({
  selector: 'app-medicines-article',
  templateUrl: './medicines-article.page.html',
  styleUrls: ['./medicines-article.page.scss'],
})
export class MedicinesArticlePage {

  @ViewChildren('subheadingRef', { read: ElementRef }) subheadingRefs: QueryList<ElementRef> | any;

  searchbarResults$: Subscription | undefined;
  webpage: any;
  locale: any;
  item: any;
  category: any;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  breadcrumbs: any[] = [];
  relatedItems: any;
  relatedCategories: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private webpageService: WebpageService,
    private preloadService: PreloadService,
    public platform: Platform,
  ) {

    this.locale = this.webpageService.locale;

    this.route.url.subscribe(async segments => {
      this.webpage = await this.webpageService.getWebpageData(this.locale);
      const segment:any = segments[0].path;
      const slug:any = this.webpageService.transformPathToSlug(segment);
      this.item = await this.webpageService.getItem(slug, `${this.locale}-articles`);
      this.item.img = `/assets/images/${this.item.image}`;
      this.preloadService.preloadImage(this.item.img);
      if (this.item) {
        this.getRelatedItemsAndCategories();
        this.webpageService.setMetaTags(this.item);
        this.webpageService.initializeSearchbar();
      }
      else {
        this.router.navigateByUrl('/');
      }
    });
  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilename(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  scrollToElement(subheadingRefName: string) {
    const elementToScroll = this.subheadingRefs.find((ref:any) => ref.nativeElement.id === subheadingRefName);
    if (elementToScroll) {
      elementToScroll.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getRelatedItemsAndCategories() {
    this.relatedItems = this.item.relatedItems;
    this.relatedItems.forEach((item:any) => {
      item.img1x = this.webpageService.transformFilename(item.image);
    });
    this.relatedCategories = this.item.relatedCategories;
    this.relatedCategories.forEach((item:any) => {
      item.img1x = this.webpageService.transformFilename(item.image);
    });
  }

}
