import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePage } from '../pages/home/home.page';
import { ArticlePage } from '../pages/article/article.page';
import { ArticlesListPage } from '../pages/articles-list/articles-list.page';
import { MedicinesArticlePage } from '../pages/medicines-article/medicines-article.page';
import { MedicinesListPage } from '../pages/medicines-list/medicines-list.page';
//import { NewsItemPage } from '../pages/news-item/news-item.page';
//import { NewsListPage } from '../pages/news-list/news-list.page';
import { PrivacyPolicyPage } from '../pages/privacy-policy/privacy-policy.page';
import { ContactPage } from '../pages/contact/contact.page';

// Function to generate item routes
function generateItemRoutes(): Routes {
  let itemRoutes:any = [];
  let itemSlugs = [
    "aciclovir-action-dosage-contraindications-indications",
    "aldara-use-contraindications-indications-side-effects",
    "allopurinol-indications-dosage-use-contraindications",
    "almotriptan-side-effects-contraindications-use-action",
    "alverine-side-effects-contraindications-use-action",
    "amorolfine-nail-lacquer-contraindications-indications-dosage-action",
    "anusol-hc-side-effects-use-indications-contraindications",
    "atrovent-dosage-use-contraindications-indications",
    "avamys-use-side-effects-dosage-indications",
    "avomine-action-dosage-use-indications",
    "azithromycin-dosage-action-use-indications",
    "azithromycin-and-doxycycline-contraindications-dosage-use-indications",
    "benzoyl-peroxide-indications-dosage-contraindications-action",
    "betacap-01-solution-contraindications-indications-action-dosage",
    "betmiga-dosage-action-use-contraindications",
    "betnesol-n-contraindications-side-effects-indications-action",
    "betnovate-action-side-effects-use-contraindications",
    "brevinor-indications-contraindications-dosage-side-effects",
    "bricanyl-contraindications-indications-use-side-effects",
    "canesten-side-effects-indications-contraindications-dosage",
    "cerazette-contraindications-action-use-dosage",
    "cerelle-side-effects-contraindications-dosage-use",
    "cialis-side-effects-use-action-indications",
    "cialis-daily-use-side-effects-dosage-action",
    "cialis-together-action-use-indications-side-effects",
    "cilest-action-indications-contraindications-side-effects",
    "cilique-dosage-side-effects-use-action",
    "cimizt-use-dosage-action-contraindications",
    "clenil-modulite-dosage-side-effects-use-indications",
    "clotrimazole-contraindications-dosage-indications-side-effects",
    "colchicine-dosage-contraindications-use-action",
    "colofac-indications-use-dosage-action",
    "condyline-contraindications-side-effects-action-dosage",
    "daktacort-side-effects-indications-action-contraindications",
    "daktarin-cream-contraindications-action-side-effects-use",
    "dalacin-cream-indications-dosage-contraindications-side-effects",
    "dermovate-side-effects-use-contraindications-action",
    "desloratadine-contraindications-indications-use-dosage",
    "desogestrel-side-effects-dosage-indications-contraindications",
    "detrusitol-contraindications-use-indications-dosage",
    "dianette-action-side-effects-dosage-contraindications",
    "differin-gel-contraindications-indications-use-side-effects",
    "diflucan-indications-action-side-effects-contraindications",
    "diprosalic-scalp-application-dosage-action-contraindications-indications",
    "doxycycline-contraindications-use-action-dosage",
    "doxycycline-for-acne-use-dosage-indications-action",
    "doxycycline-for-malaria-side-effects-contraindications-action-dosage",
    "duac-gel-dosage-use-action-side-effects",
    "efracea-side-effects-contraindications-dosage-use",
    "elevin-contraindications-side-effects-indications-use",
    "ellaone-indications-dosage-contraindications-use",
    "elleste-contraindications-use-dosage-indications",
    "elleste-duet-side-effects-indications-dosage-contraindications",
    "elocon-dosage-indications-use-contraindications",
    "eloine-contraindications-indications-dosage-use",
    "emla-cream-action-dosage-use-side-effects",
    "epiduo-contraindications-use-action-dosage",
    "esomeprazole-indications-contraindications-action-side-effects",
    "estriol-cream-use-contraindications-indications-dosage",
    "eumovate-contraindications-indications-action-side-effects",
    "evorel-indications-dosage-use-side-effects",
    "evorel-conti-dosage-contraindications-indications-use",
    "evorel-sequi-side-effects-dosage-action-indications",
    "evra-patch-dosage-use-contraindications-action",
    "famvir-action-side-effects-dosage-contraindications",
    "feanolla-use-contraindications-action-side-effects",
    "femodene-action-use-contraindications-indications",
    "femodene-ed-use-contraindications-action-dosage",
    "femodette-dosage-contraindications-use-side-effects",
    "femoston-contraindications-use-side-effects-action",
    "femoston-conti-dosage-use-indications-contraindications",
    "fexofenadine-contraindications-use-dosage-side-effects",
    "finacea-indications-side-effects-contraindications-dosage",
    "finasteride-indications-use-action-dosage",
    "flixotide-side-effects-use-indications-contraindications",
    "fluconazole-action-side-effects-dosage-contraindications",
    "fludroxycortide-tape-indications-dosage-contraindications-side-effects",
    "fluomizin-side-effects-dosage-action-contraindications",
    "fostair-side-effects-indications-use-contraindications",
    "fucibet-cream-action-indications-contraindications-side-effects",
    "fucidin-h-cream-side-effects-use-indications-dosage",
    "gedarel-side-effects-use-action-contraindications",
    "generic-impotence-trial-pack-contraindications-indications-use-dosage",
    "gyno-daktarin-action-indications-use-side-effects",
    "hydrocortisone-use-action-contraindications-side-effects",
    "impotence-trial-pack-side-effects-contraindications-use-action",
    "katya-indications-action-use-side-effects",
    "kliofem-side-effects-dosage-contraindications-action",
    "kliovance-dosage-indications-use-side-effects",
    "kwells-side-effects-action-contraindications-indications",
    "lansoprazole-contraindications-use-dosage-indications",
    "levest-dosage-use-contraindications-side-effects",
    "levitra-side-effects-indications-contraindications-use",
    "levitra-orodispersible-dosage-contraindications-indications-side-effects",
    "levocetirizine-contraindications-action-indications-side-effects",
    "levonelle-contraindications-dosage-indications-use",
    "levonorgestrel-dosage-use-side-effects-indications",
    "livial-contraindications-dosage-use-action",
    "lizinna-action-use-indications-dosage",
    "loceryl-nail-lacquer-use-indications-dosage-action",
    "logynon-use-action-contraindications-indications",
    "lovima-contraindications-side-effects-action-use",
    "lucette-dosage-contraindications-side-effects-use",
    "lymecycline-contraindications-indications-use-side-effects",
    "maexeni-indications-use-side-effects-contraindications",
    "malarone-indications-dosage-action-use",
    "marvelon-dosage-contraindications-use-action",
    "mefenamic-acid-side-effects-action-dosage-contraindications",
    "melatonin-use-dosage-action-contraindications",
    "mercilon-action-dosage-use-indications",
    "metronidazole-contraindications-action-indications-side-effects",
    "microgynon-dosage-indications-side-effects-use",
    "microgynon-30-ed-dosage-contraindications-action-indications",
    "millinette-contraindications-indications-side-effects-use",
    "mizollen-use-side-effects-action-indications",
    "mometasone-indications-action-dosage-side-effects",
    "mysimba-dosage-contraindications-use-side-effects",
    "naproxen-action-dosage-use-contraindications",
    "nexium-contraindications-indications-dosage-side-effects",
    "nitrofurantoin-contraindications-indications-side-effects-action",
    "norethisterone-contraindications-action-use-side-effects",
    "norgeston-indications-dosage-contraindications-side-effects",
    "noriday-indications-use-contraindications-action",
    "norinyl-1-indications-dosage-contraindications-action",
    "nuvaring-side-effects-contraindications-use-dosage",
    "omeprazole-use-dosage-action-indications",
    "optilast-dosage-use-action-indications",
    "orlistat-use-action-dosage-side-effects",
    "otomize-ear-spray-side-effects-dosage-indications-use",
    "ovranette-side-effects-use-contraindications-action",
    "oxybutynin-indications-contraindications-side-effects-use",
    "oxytetracycline-dosage-action-contraindications-indications",
    "pantoprazole-indications-dosage-side-effects-use",
    "premarin-use-indications-side-effects-contraindications",
    "premique-dosage-use-side-effects-indications",
    "priligy-indications-side-effects-contraindications-dosage",
    "proctosedyl-dosage-contraindications-indications-side-effects",
    "propecia-action-use-indications-contraindications",
    "pulmicort-indications-side-effects-action-dosage",
    "qlaira-action-indications-side-effects-dosage",
    "qvar-indications-dosage-use-contraindications",
    "rabeprazole-side-effects-indications-dosage-contraindications",
    "rhinolast-side-effects-use-indications-contraindications",
    "rigevidon-contraindications-use-indications-dosage",
    "salamol-side-effects-dosage-contraindications-action",
    "saxenda-indications-side-effects-use-action",
    "sayana-press-use-side-effects-indications-contraindications",
    "scheriproct-use-indications-contraindications-dosage",
    "scopoderm-action-side-effects-indications-dosage",
    "seretide-side-effects-use-action-dosage",
    "serevent-side-effects-use-dosage-indications",
    "sildenafil-indications-action-side-effects-dosage",
    "skinoren-indications-use-side-effects-action",
    "spedra-contraindications-side-effects-indications-use",
    "spiriva-respimat-dosage-side-effects-action-use",
    "sumatriptan-indications-dosage-action-use",
    "sunya-action-side-effects-use-indications",
    "symbicort-side-effects-dosage-use-action",
    "tadalafil-dosage-action-side-effects-contraindications",
    "tamiflu-side-effects-use-indications-action",
    "telfast-dosage-side-effects-action-contraindications",
    "terbinafine-tablets-action-side-effects-dosage-use",
    "treclin-gel-use-side-effects-contraindications-indications",
    "trimethoprim-side-effects-contraindications-dosage-use",
    "trimovate-contraindications-indications-use-side-effects",
    "triregol-dosage-indications-contraindications-use",
    "uniroid-hc-action-contraindications-side-effects-indications",
    "valtrex-contraindications-side-effects-dosage-indications",
    "vaniqa-side-effects-dosage-indications-action",
    "vardenafil-use-indications-action-contraindications",
    "ventolin-dosage-action-use-indications",
    "vesicare-side-effects-contraindications-dosage-action",
    "viagra-side-effects-use-action-contraindications",
    "viagra-connect-side-effects-contraindications-dosage-action",
    "vydura-contraindications-indications-dosage-use",
    "warticon-indications-action-dosage-side-effects",
    "wegovy-action-dosage-use-side-effects",
    "xenical-dosage-use-side-effects-indications",
    "xyloproct-contraindications-action-side-effects-use",
    "yacella-action-use-side-effects-dosage",
    "yasmin-indications-side-effects-action-dosage",
    "zelleta-dosage-side-effects-action-contraindications",
    "zineryt-contraindications-indications-dosage-action",
    "zoely-indications-side-effects-action-dosage",
    "zomig-use-dosage-side-effects-action",
    "zumenon-side-effects-contraindications-indications-dosage",
    "zyban-use-action-indications-side-effects"
];
  itemSlugs.forEach((slug:any) => {
    itemRoutes.push({ path: `${slug}`, component: MedicinesArticlePage });
  })
  return itemRoutes;
}

// Base routes
  const baseRoutes: Routes  = [
    { path: 'contact', component: ContactPage },
    { path: 'privacy-policy', component: PrivacyPolicyPage },
    { path: 'health', component: ArticlesListPage },
    { path: 'health/medicines-from-a-to-z', component: MedicinesListPage },
    { path: 'health/:itemSlug', component: ArticlesListPage },
    // { path: 'news', component: NewsListPage },
    // { path: 'news/:itemSlug', component: NewsItemPage },
    { path: 'articles/:itemSlug', component: ArticlePage },
    { path: 'nutrition', component: ArticlesListPage },
    { path: 'nutrition/:itemSlug', component: ArticlesListPage },
    { path: 'wellness', component: ArticlesListPage },
    { path: 'wellness/:itemSlug', component: ArticlesListPage },
    { path: '', component: HomePage },
    { path: '**', redirectTo: '', pathMatch: 'full'}
  ];

// Combine base routes with generated item routes
const routes: Routes = [
  ...generateItemRoutes(),
  ...baseRoutes
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
