import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras} from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { WebpageService } from '../../services/web.service';
import { TextService } from 'src/services/text.service';
import { Subscription } from 'rxjs';
import { InfiniteScrollCustomEvent} from '@ionic/angular';

@Component({
  selector: 'app-medicines-list',
  templateUrl: './medicines-list.page.html',
  styleUrls: ['./medicines-list.page.scss'],
})
export class MedicinesListPage {

  webpage:any;
  locale: any;
  searchbarResults$: Subscription | undefined;
  category: any;
  items: any[] = [];
  maxItems: any;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  pageItems = 100;
  breadcrumbs: any[] = [];
  images = {};
  imageAppUrl: any;

  products: any[] = [];
  groupedProducts: any;
  sortedProducts: any;
  showData = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private meta: Meta,
    public text: TextService,
    private webpageService: WebpageService

  ) {

    this.locale = this.webpageService.locale;

    this.route.url.subscribe(async segments => {
      this.webpage = await this.webpageService.getWebpageData(this.locale);

        const segment:any = segments[1].path;
        this.category = await this.webpageService.getItem(segment, `${this.locale}-categories`);
        this.products = [];
        this.groupedProducts = {};
        this.products = this.category.children;
        // two first letters to improve webpage load performance
        this.groupProductsByLetter(this.products, ['A', 'B']);
        this.sortedProducts = this.getGroupedProductKeys();
        this.maxItems = this.category.children.length;
        this.items = this.category.children;
        this.setMetaTags();
        this.webpageService.initializeSearchbar();
    })
  }


  getGroupedProductKeys(): string[] {
    return Object.keys(this.groupedProducts);
  }

  groupProductsByLetter(products: any, letters: string[] = ['A', 'B']): void {
    products.sort((a: any, b: any) => a.name.localeCompare(b.name));
    products.forEach((product: any) => {
      const firstLetter = product.name[0].toUpperCase();
      if (letters.includes(firstLetter) || letters.length === 0) {
        if (!this.groupedProducts[firstLetter]) {
          this.groupedProducts[firstLetter] = [];
        }
        this.groupedProducts[firstLetter].push(product);
      }
    });
  }

  loadMoreData(ev:any) {
    if(!this.showData) {
      this.showData = true;
      this.groupProductsByLetter(this.products, []);
      this.sortedProducts = this.getGroupedProductKeys();
      (ev as InfiniteScrollCustomEvent).target.complete();
    }
  }

  goToMainPage() { this.router.navigateByUrl('/'); }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilename(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }


  setMetaTags() {
    this.title.setTitle(this.category.metaTitle);
    this.meta.updateTag({name:'description', content: this.category.metaDescription});
    this.meta.updateTag({name:'keywords', content: this.category.keywords});

    //Facebook
    this.meta.updateTag({name:'og:type', content: 'website'});
    this.meta.updateTag({name:'og:site_name', content: this.webpage.name });
    this.meta.updateTag({name:'og:url', content: `https://${this.webpage.name}` });
    this.meta.updateTag({name:'og:title', content: this.category.metaTitle});
    this.meta.updateTag({name:'og:description', content: this.category.metaDescription});
    this.meta.updateTag({name:'og:image', content: `https://${this.webpage.name}/assets/images/${this.category.slug}.jpg`}); // Recommend 1200×628

    this.meta.updateTag({name:'twitter:card', content: "summary_large_image"});
    this.meta.updateTag({name:'twitter:url', content: `https://${this.webpage.name}` });
    this.meta.updateTag({name:'twitter:title', content: this.category.metaTitle});
    this.meta.updateTag({name:'twitter:description', content: this.category.metaDescription});
    this.meta.updateTag({name:'twitter:image', content: `https://${this.webpage.name}/assets/images/${this.category.slug}-1x.jpg`});
  }

}