<ion-row class="header-space"></ion-row>
<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col class="ads" size="2"></ion-col>
      <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" class="ion-no-padding">
        <ion-list class="main-list" *ngIf="searchbarInputLength === 0" role="list">
          <div class="h1-container background-health" role="listitem"><h1 class="category-name">{{text.home.subheadingA}}</h1></div>

          <ion-row class="background-health" >
            <ion-col role="listitem" size-sm="12" size-md="6" *ngFor="let item of articlesHealth | slice:0:1">
                <div class="item-container health zoom-effect" [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
                  <div class="articles-list-image-container">
                    <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}"></ion-skeleton-text>
                    <ion-img alt="{{item?.imageAlt}}" src="{{'assets/images/' + item.image}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
                  </div>
                  <span class="home-item-name-promoted fadeIn">{{item.title}}</span>
                  <span class="item-extract">{{item.extract}}</span>
                </div>
            </ion-col>
            <ion-col role="listitem" size-xs="12" size-md="6">
              <ion-row class="ion-no-padding">
                <ion-col size-xs="12" size-sm="6" size-md="6" *ngFor="let item of articlesHealth | slice:1:5">
                  <div class="item-container health zoom-effect" [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
                    <div class="articles-list-image-container">
                      <ion-skeleton-text aria-hidden="true" ></ion-skeleton-text>
                      <ion-img alt="{{item?.imageAlt}}" src="{{item?.img1x}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
                    </div>
                    <span class="home-item-name fadeIn">{{item.title}}</span>
                  </div>
                </ion-col>
              </ion-row>
            </ion-col>
            <div role="listitem">
              <ul role="list">
                <li class="health zoom-effect" *ngFor="let item of articlesHealth | slice:5:8">
                  <a [routerLink]="'/' + item.path" [state]="{data: item}" [attr.aria-label]="text.home.ariaLabel + item?.name" role="button" tabindex="0" >
                    <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> {{item.title}}
                  </a>
                </li>
              </ul>
            </div>
            <div class="button-container" role="listitem">
              <ion-button class="button-health" [routerLink]="'/health'"  expand="block" fill="solid">
                {{text.home.buttonTextA}}
              </ion-button>
            </div>
          </ion-row>

          <ion-infinite-scroll threshold="40%" (ionInfinite)="loadMoreData($event)">
            <ion-infinite-scroll-content *ngIf="!showData" loadingSpinner="bubbles"></ion-infinite-scroll-content>
          </ion-infinite-scroll>

          <div *ngIf="showData" class="h1-container background-nutrition" role="listitem"><h1 class="category-name">{{text.home.subheadingB}}</h1></div>

          <ion-row *ngIf="showData" class="background-nutrition">
            <ion-col class="ion-no-padding" role="listitem" size-xs="12" size-md="6">
              <ion-row class="ion-no-padding">
                <ion-col size-xs="12" size-sm="6" size-md="6" *ngFor="let item of articlesNutrition | slice:1:5">
                  <div class="item-container nutrition zoom-effect" [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
                    <div class="articles-list-image-container">
                      <ion-skeleton-text aria-hidden="true" ></ion-skeleton-text>
                      <ion-img alt="{{item?.imageAlt}}" src="{{item?.img1x}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
                    </div>
                    <span class="home-item-name fadeIn">{{item.title}}</span>
                  </div>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-col role="listitem" size-sm="12" size-md="6" *ngFor="let item of articlesNutrition | slice:0:1">
                <div class="item-container nutrition zoom-effect" [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
                  <div class="articles-list-image-container">
                    <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}"></ion-skeleton-text>
                    <ion-img alt="{{item?.imageAlt}}" src="{{'assets/images/' + item.image}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
                  </div>
                  <span class="home-item-name-promoted fadeIn">{{item.title}}</span>
                  <span class="item-extract">{{item.extract}}</span>
                </div>
            </ion-col>
            <div role="listitem">
              <ul role="list">
                <li class="nutrition zoom-effect" *ngFor="let item of articlesNutrition | slice:5:8">
                  <a [routerLink]="'/' + item.path" [state]="{data: item}" [attr.aria-label]="text.home.ariaLabel + item?.name" role="button" tabindex="0" >
                    <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> {{item.title}}
                  </a>
                </li>
              </ul>
            </div>
            <div class="button-container" role="listitem">
              <ion-button class="button-nutrition" [routerLink]="'/nutrition'"  expand="block" fill="solid">
                {{text.home.buttonTextB}}
              </ion-button>
            </div>
          </ion-row>

          <div *ngIf="showData" class="h1-container background-wellness" role="listitem"><h1 class="category-name">{{text.home.subheadingC}}</h1></div>


      <ion-row *ngIf="showData" class="background-wellness">
        <ion-col role="listitem" size-sm="12" size-md="6" *ngFor="let item of articlesWellness | slice:0:1">
            <div class="item-container wellness zoom-effect" [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
              <div class="articles-list-image-container">
                <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}"></ion-skeleton-text>
                <ion-img alt="{{item?.imageAlt}}" src="{{'assets/images/' + item.image}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
              </div>
              <span class="home-item-name-promoted fadeIn">{{item.title}}</span>
              <span class="item-extract">{{item.extract}}</span>
            </div>
        </ion-col>
        <ion-col class="ion-no-padding" role="listitem" size-xs="12" size-md="6">
          <ion-row class="ion-no-padding">
            <ion-col size-xs="12" size-sm="6" size-md="6" *ngFor="let item of articlesWellness | slice:1:5">
              <div class="item-container wellness zoom-effect" [routerLink]="'/' + item.path" [state]="{data: item}" role="button" tabindex="0" [attr.aria-label]="text.home.ariaLabel + item?.name">
                <div class="articles-list-image-container">
                  <ion-skeleton-text aria-hidden="true" ></ion-skeleton-text>
                  <ion-img alt="{{item?.imageAlt}}" src="{{item?.img1x}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
                </div>
                <span class="home-item-name fadeIn">{{item.title}}</span>
              </div>
            </ion-col>
        </ion-row>
      </ion-col>
      <div role="listitem">
        <ul role="list">
          <li class="wellness zoom-effect" *ngFor="let item of articlesWellness | slice:5:8">
            <a [routerLink]="'/' + item.path" [state]="{data: item}" [attr.aria-label]="text.home.ariaLabel + item?.name" role="button" tabindex="0" >
              <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> {{item.title}}
            </a>
          </li>
        </ul>
      </div>
      <div class="button-container" role="listitem">
        <ion-button class="button-wellness" [routerLink]="'/wellness'" expand="block" fill="solid">
          {{text.home.buttonTextC}}
        </ion-button>
      </div>
      </ion-row>
    </ion-list>

    <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>
    <app-footer></app-footer>
    </ion-col>
    <ion-col class="ads" size="2"></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
