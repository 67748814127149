<footer>
  <div class="container">
    <nav class="footer-nav" [attr.aria-label]="text.footer.label">
      <ul>
        <li><a routerLink="/contact">{{text.footer.contact}}</a></li>
        <li><a routerLink="/privacy-policy">{{text.footer.privacyPolicy}}</a></li>
        <li><a type="button" data-cc="show-preferencesModal">Cookie preferences</a></li>
      </ul>
    </nav>
    <span class="copyrights">Copyright © 2024 Medilight. All rights reserved.</span>
  </div>
</footer>