import { Injectable, makeStateKey, TransferState, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import Fuse from 'fuse.js';
import { BehaviorSubject } from 'rxjs';
import { firstValueFrom, Observable } from 'rxjs';
import webpage from './../assets/webpage.json';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class WebpageService {

  webpage:any = webpage;
  items: any;
  //items = items;
  myFuseOptions = {
    caseSensitive: false,
    keys: ["n", "e", "k"],
    shouldSort: true,
  };
  myFuse: any;
  searchResults = new BehaviorSubject<any>({items: [], searchbarInputLength: 0});
  isBrowser: any;
  clearSearchSubject = new BehaviorSubject<boolean>(false);
  rootCategoriesSubject = new BehaviorSubject<any>([]);
  localeSubject = new BehaviorSubject<any>(null);
  rootCategories:any;
  locale = 'en';
  sublocale = 'uk';
  showSearchBar: boolean = false;
  FIREBASE_WEBPANEL_FUNCTIONS_URL = "https://us-east1-webpanel-admin.cloudfunctions.net/myFunctions/"

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: Object,
    private transferState: TransferState,
    private title: Title,
    private router: Router,
    private meta: Meta) {

    this.isBrowser = isPlatformBrowser(platformId);
  }

  triggerClear() { this.clearSearchSubject.next(true); }

  clearCompleted() { this.clearSearchSubject.next(false); }

  search(searchbarInput:any) {
    const items = this.myFuse.search(searchbarInput).map((i:any) => i.item);
    this.searchResults.next({items, searchbarInputLength: items.length });
  }

  getDataFromJSONFile(folder:any, slug:any) { return firstValueFrom(this.http.get(`/assets/${folder}/${slug}.json`)); }

  getWebpageFileJSON() { return firstValueFrom(this.http.get(`/assets/webpage.json`)); }


  initializeSearchbar(){
    if(this.isBrowser) {
      if(!this.myFuse) {

        this.http.get(`/assets/searchbars/${this.locale}-searchbar.json`).subscribe((data) => {
          this.items = data;
          const myFuseIndex = Fuse.createIndex(this.myFuseOptions.keys, this.items);
          this.myFuse = new Fuse(this.items, this.myFuseOptions, myFuseIndex);
        });
      }
    }
    else {
     /* let fs = require('fs');
      let data = JSON.parse(fs.readFileSync(`dist/functions/browser/assets/searchbars/${locale}-searchbar.json`, 'utf8'));
      this.items = data;
      let key = `${locale}-searchbar`;
      const transferStateKey = makeStateKey<any>(key);
      this.transferState.set(transferStateKey, data);*/
    }
  }

  sendMessageViaContactForm(data: any) {
    const url = this.FIREBASE_WEBPANEL_FUNCTIONS_URL + 'sendMessageViaContactForm';
    return this.http.post(url, data);
  }

  clearSearchbarInput() { this.searchResults.next({items: [], searchbarInputLength: 0 }); }

  async openSearchbarItem(item: any) {
    const path = item.p;
    const navigationExtras: NavigationExtras = { state: { slug: item.s }};
    this.clearSearchbarInput();
    this.router.navigate([path], navigationExtras);
    this.triggerClear();
  }

  getSearchbarData (searchbarInput: any) { return this.myFuse.search(searchbarInput, {limit: 12}).map((i:any) => i.item); }

  async getItem(slug:any, folder:any) {
    if(this.isBrowser) {
      if (this.transferState.hasKey(slug)) {
        let item = this.transferState.get(slug, null);
        if (folder === 'roots') { this.rootCategoriesSubject.next(item);}
        //this.transferState.remove(slug); // Optional: remove the key after retrieving it
        return item;
      } else {
        let item = await this.getDataFromJSONFile(folder, slug);
        if (folder === 'roots') { this.rootCategoriesSubject.next(item);}
        return item;
      }
    }
    else {
      let fs = require('fs');
      let item = JSON.parse(fs.readFileSync(`dist/functions/browser/assets/${folder}/${slug}.json`, 'utf8'));
      const transferStateKey = makeStateKey<any>(slug);
      this.transferState.set(transferStateKey, item);
      return item;
    }
  }

  async getWebpageData(locale:any) {
    const slug:any = `webpage-${locale}`;
    if (this.isBrowser) {
      if (this.transferState.hasKey(slug)) {
        let webpage:any = this.transferState.get(slug, null);
        return webpage;
      } else {

        let webpage:any = await this.getWebpageFileJSON();
        webpage = {...webpage, ...webpage.locales.find((item:any) => { return item.locale === locale; }) };
        return webpage;
      }
    }
    else {
      let fs = require('fs');
      let webpage:any = JSON.parse(fs.readFileSync(`dist/functions/browser/assets/webpage.json`, 'utf8'));
      webpage = {...webpage, ...webpage.locales.find((item:any) => { return item.locale === locale; }) };
      const transferStateKey = makeStateKey<any>(slug);
      this.transferState.set(transferStateKey, webpage);
      return webpage;
    }
  }

  getRootCategories() { return this.rootCategoriesSubject.asObservable().pipe();  }

  getSearchbarResults() { return this.searchResults.asObservable().pipe(); }

  slugify (str:any) { return str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '')};

  getShortnameForArticle(slug:any) {
    let splitSlug = slug.split('-');
    return splitSlug.join(' ');
  }

  transformPathToSlug(path:any) {
    let paths:any = {
      "aciclovir-action-dosage-contraindications-indications": "aciclovir",
      "aldara-use-contraindications-indications-side-effects": "aldara",
      "allopurinol-indications-dosage-use-contraindications": "allopurinol",
      "almotriptan-side-effects-contraindications-use-action": "almotriptan",
      "alverine-side-effects-contraindications-use-action": "alverine",
      "amorolfine-nail-lacquer-contraindications-indications-dosage-action": "amorolfine-nail-lacquer",
      "anusol-hc-side-effects-use-indications-contraindications": "anusol-hc",
      "atrovent-dosage-use-contraindications-indications": "atrovent",
      "avamys-use-side-effects-dosage-indications": "avamys",
      "avomine-action-dosage-use-indications": "avomine",
      "azithromycin-dosage-action-use-indications": "azithromycin",
      "azithromycin-and-doxycycline-contraindications-dosage-use-indications": "azithromycin-and-doxycycline",
      "benzoyl-peroxide-indications-dosage-contraindications-action": "benzoyl-peroxide",
      "betacap-01-solution-contraindications-indications-action-dosage": "betacap-01-solution",
      "betmiga-dosage-action-use-contraindications": "betmiga",
      "betnesol-n-contraindications-side-effects-indications-action": "betnesol-n",
      "betnovate-action-side-effects-use-contraindications": "betnovate",
      "brevinor-indications-contraindications-dosage-side-effects": "brevinor",
      "bricanyl-contraindications-indications-use-side-effects": "bricanyl",
      "canesten-side-effects-indications-contraindications-dosage": "canesten",
      "cerazette-contraindications-action-use-dosage": "cerazette",
      "cerelle-side-effects-contraindications-dosage-use": "cerelle",
      "cialis-side-effects-use-action-indications": "cialis",
      "cialis-daily-use-side-effects-dosage-action": "cialis-daily",
      "cialis-together-action-use-indications-side-effects": "cialis-together",
      "cilest-action-indications-contraindications-side-effects": "cilest",
      "cilique-dosage-side-effects-use-action": "cilique",
      "cimizt-use-dosage-action-contraindications": "cimizt",
      "clenil-modulite-dosage-side-effects-use-indications": "clenil-modulite",
      "clotrimazole-contraindications-dosage-indications-side-effects": "clotrimazole",
      "colchicine-dosage-contraindications-use-action": "colchicine",
      "colofac-indications-use-dosage-action": "colofac",
      "condyline-contraindications-side-effects-action-dosage": "condyline",
      "daktacort-side-effects-indications-action-contraindications": "daktacort",
      "daktarin-cream-contraindications-action-side-effects-use": "daktarin-cream",
      "dalacin-cream-indications-dosage-contraindications-side-effects": "dalacin-cream",
      "dermovate-side-effects-use-contraindications-action": "dermovate",
      "desloratadine-contraindications-indications-use-dosage": "desloratadine",
      "desogestrel-side-effects-dosage-indications-contraindications": "desogestrel",
      "detrusitol-contraindications-use-indications-dosage": "detrusitol",
      "dianette-action-side-effects-dosage-contraindications": "dianette",
      "differin-gel-contraindications-indications-use-side-effects": "differin-gel",
      "diflucan-indications-action-side-effects-contraindications": "diflucan",
      "diprosalic-scalp-application-dosage-action-contraindications-indications": "diprosalic-scalp-application",
      "doxycycline-contraindications-use-action-dosage": "doxycycline",
      "doxycycline-for-acne-use-dosage-indications-action": "doxycycline-for-acne",
      "doxycycline-for-malaria-side-effects-contraindications-action-dosage": "doxycycline-for-malaria",
      "duac-gel-dosage-use-action-side-effects": "duac-gel",
      "efracea-side-effects-contraindications-dosage-use": "efracea",
      "elevin-contraindications-side-effects-indications-use": "elevin",
      "ellaone-indications-dosage-contraindications-use": "ellaone",
      "elleste-contraindications-use-dosage-indications": "elleste",
      "elleste-duet-side-effects-indications-dosage-contraindications": "elleste-duet",
      "elocon-dosage-indications-use-contraindications": "elocon",
      "eloine-contraindications-indications-dosage-use": "eloine",
      "emla-cream-action-dosage-use-side-effects": "emla-cream",
      "epiduo-contraindications-use-action-dosage": "epiduo",
      "esomeprazole-indications-contraindications-action-side-effects": "esomeprazole",
      "estriol-cream-use-contraindications-indications-dosage": "estriol-cream",
      "eumovate-contraindications-indications-action-side-effects": "eumovate",
      "evorel-indications-dosage-use-side-effects": "evorel",
      "evorel-conti-dosage-contraindications-indications-use": "evorel-conti",
      "evorel-sequi-side-effects-dosage-action-indications": "evorel-sequi",
      "evra-patch-dosage-use-contraindications-action": "evra-patch",
      "famvir-action-side-effects-dosage-contraindications": "famvir",
      "feanolla-use-contraindications-action-side-effects": "feanolla",
      "femodene-action-use-contraindications-indications": "femodene",
      "femodene-ed-use-contraindications-action-dosage": "femodene-ed",
      "femodette-dosage-contraindications-use-side-effects": "femodette",
      "femoston-contraindications-use-side-effects-action": "femoston",
      "femoston-conti-dosage-use-indications-contraindications": "femoston-conti",
      "fexofenadine-contraindications-use-dosage-side-effects": "fexofenadine",
      "finacea-indications-side-effects-contraindications-dosage": "finacea",
      "finasteride-indications-use-action-dosage": "finasteride",
      "flixotide-side-effects-use-indications-contraindications": "flixotide",
      "fluconazole-action-side-effects-dosage-contraindications": "fluconazole",
      "fludroxycortide-tape-indications-dosage-contraindications-side-effects": "fludroxycortide-tape",
      "fluomizin-side-effects-dosage-action-contraindications": "fluomizin",
      "fostair-side-effects-indications-use-contraindications": "fostair",
      "fucibet-cream-action-indications-contraindications-side-effects": "fucibet-cream",
      "fucidin-h-cream-side-effects-use-indications-dosage": "fucidin-h-cream",
      "gedarel-side-effects-use-action-contraindications": "gedarel",
      "generic-impotence-trial-pack-contraindications-indications-use-dosage": "generic-impotence-trial-pack",
      "gyno-daktarin-action-indications-use-side-effects": "gyno-daktarin",
      "hydrocortisone-use-action-contraindications-side-effects": "hydrocortisone",
      "impotence-trial-pack-side-effects-contraindications-use-action": "impotence-trial-pack",
      "katya-indications-action-use-side-effects": "katya",
      "kliofem-side-effects-dosage-contraindications-action": "kliofem",
      "kliovance-dosage-indications-use-side-effects": "kliovance",
      "kwells-side-effects-action-contraindications-indications": "kwells",
      "lansoprazole-contraindications-use-dosage-indications": "lansoprazole",
      "levest-dosage-use-contraindications-side-effects": "levest",
      "levitra-side-effects-indications-contraindications-use": "levitra",
      "levitra-orodispersible-dosage-contraindications-indications-side-effects": "levitra-orodispersible",
      "levocetirizine-contraindications-action-indications-side-effects": "levocetirizine",
      "levonelle-contraindications-dosage-indications-use": "levonelle",
      "levonorgestrel-dosage-use-side-effects-indications": "levonorgestrel",
      "livial-contraindications-dosage-use-action": "livial",
      "lizinna-action-use-indications-dosage": "lizinna",
      "loceryl-nail-lacquer-use-indications-dosage-action": "loceryl-nail-lacquer",
      "logynon-use-action-contraindications-indications": "logynon",
      "lovima-contraindications-side-effects-action-use": "lovima",
      "lucette-dosage-contraindications-side-effects-use": "lucette",
      "lymecycline-contraindications-indications-use-side-effects": "lymecycline",
      "maexeni-indications-use-side-effects-contraindications": "maexeni",
      "malarone-indications-dosage-action-use": "malarone",
      "marvelon-dosage-contraindications-use-action": "marvelon",
      "mefenamic-acid-side-effects-action-dosage-contraindications": "mefenamic-acid",
      "melatonin-use-dosage-action-contraindications": "melatonin",
      "mercilon-action-dosage-use-indications": "mercilon",
      "metronidazole-contraindications-action-indications-side-effects": "metronidazole",
      "microgynon-dosage-indications-side-effects-use": "microgynon",
      "microgynon-30-ed-dosage-contraindications-action-indications": "microgynon-30-ed",
      "millinette-contraindications-indications-side-effects-use": "millinette",
      "mizollen-use-side-effects-action-indications": "mizollen",
      "mometasone-indications-action-dosage-side-effects": "mometasone",
      "mysimba-dosage-contraindications-use-side-effects": "mysimba",
      "naproxen-action-dosage-use-contraindications": "naproxen",
      "nexium-contraindications-indications-dosage-side-effects": "nexium",
      "nitrofurantoin-contraindications-indications-side-effects-action": "nitrofurantoin",
      "norethisterone-contraindications-action-use-side-effects": "norethisterone",
      "norgeston-indications-dosage-contraindications-side-effects": "norgeston",
      "noriday-indications-use-contraindications-action": "noriday",
      "norinyl-1-indications-dosage-contraindications-action": "norinyl-1",
      "nuvaring-side-effects-contraindications-use-dosage": "nuvaring",
      "omeprazole-use-dosage-action-indications": "omeprazole",
      "optilast-dosage-use-action-indications": "optilast",
      "orlistat-use-action-dosage-side-effects": "orlistat",
      "otomize-ear-spray-side-effects-dosage-indications-use": "otomize-ear-spray",
      "ovranette-side-effects-use-contraindications-action": "ovranette",
      "oxybutynin-indications-contraindications-side-effects-use": "oxybutynin",
      "oxytetracycline-dosage-action-contraindications-indications": "oxytetracycline",
      "pantoprazole-indications-dosage-side-effects-use": "pantoprazole",
      "premarin-use-indications-side-effects-contraindications": "premarin",
      "premique-dosage-use-side-effects-indications": "premique",
      "priligy-indications-side-effects-contraindications-dosage": "priligy",
      "proctosedyl-dosage-contraindications-indications-side-effects": "proctosedyl",
      "propecia-action-use-indications-contraindications": "propecia",
      "pulmicort-indications-side-effects-action-dosage": "pulmicort",
      "qlaira-action-indications-side-effects-dosage": "qlaira",
      "qvar-indications-dosage-use-contraindications": "qvar",
      "rabeprazole-side-effects-indications-dosage-contraindications": "rabeprazole",
      "rhinolast-side-effects-use-indications-contraindications": "rhinolast",
      "rigevidon-contraindications-use-indications-dosage": "rigevidon",
      "salamol-side-effects-dosage-contraindications-action": "salamol",
      "saxenda-indications-side-effects-use-action": "saxenda",
      "sayana-press-use-side-effects-indications-contraindications": "sayana-press",
      "scheriproct-use-indications-contraindications-dosage": "scheriproct",
      "scopoderm-action-side-effects-indications-dosage": "scopoderm",
      "seretide-side-effects-use-action-dosage": "seretide",
      "serevent-side-effects-use-dosage-indications": "serevent",
      "sildenafil-indications-action-side-effects-dosage": "sildenafil",
      "skinoren-indications-use-side-effects-action": "skinoren",
      "spedra-contraindications-side-effects-indications-use": "spedra",
      "spiriva-respimat-dosage-side-effects-action-use": "spiriva-respimat",
      "sumatriptan-indications-dosage-action-use": "sumatriptan",
      "sunya-action-side-effects-use-indications": "sunya",
      "symbicort-side-effects-dosage-use-action": "symbicort",
      "tadalafil-dosage-action-side-effects-contraindications": "tadalafil",
      "tamiflu-side-effects-use-indications-action": "tamiflu",
      "telfast-dosage-side-effects-action-contraindications": "telfast",
      "terbinafine-tablets-action-side-effects-dosage-use": "terbinafine-tablets",
      "treclin-gel-use-side-effects-contraindications-indications": "treclin-gel",
      "trimethoprim-side-effects-contraindications-dosage-use": "trimethoprim",
      "trimovate-contraindications-indications-use-side-effects": "trimovate",
      "triregol-dosage-indications-contraindications-use": "triregol",
      "uniroid-hc-action-contraindications-side-effects-indications": "uniroid-hc",
      "valtrex-contraindications-side-effects-dosage-indications": "valtrex",
      "vaniqa-side-effects-dosage-indications-action": "vaniqa",
      "vardenafil-use-indications-action-contraindications": "vardenafil",
      "ventolin-dosage-action-use-indications": "ventolin",
      "vesicare-side-effects-contraindications-dosage-action": "vesicare",
      "viagra-side-effects-use-action-contraindications": "viagra",
      "viagra-connect-side-effects-contraindications-dosage-action": "viagra-connect",
      "vydura-contraindications-indications-dosage-use": "vydura",
      "warticon-indications-action-dosage-side-effects": "warticon",
      "wegovy-action-dosage-use-side-effects": "wegovy",
      "xenical-dosage-use-side-effects-indications": "xenical",
      "xyloproct-contraindications-action-side-effects-use": "xyloproct",
      "yacella-action-use-side-effects-dosage": "yacella",
      "yasmin-indications-side-effects-action-dosage": "yasmin",
      "zelleta-dosage-side-effects-action-contraindications": "zelleta",
      "zineryt-contraindications-indications-dosage-action": "zineryt",
      "zoely-indications-side-effects-action-dosage": "zoely",
      "zomig-use-dosage-side-effects-action": "zomig",
      "zumenon-side-effects-contraindications-indications-dosage": "zumenon",
      "zyban-use-action-indications-side-effects": "zyban"
    };
    return paths[path];
  }

  setMetaTags(item:any) {
    //console.log('Setting meta tags...');
    this.title.setTitle(item.metaTitle);
    this.meta.updateTag({name:'description', content: item.metaDescription});
    this.meta.updateTag({name:'keywords', content: item.keywords});

    this.meta.updateTag({name:'og:type', content: 'website'});
    this.meta.updateTag({name:'og:site_name', content: this.webpage.name});
    this.meta.updateTag({name:'og:url', content: `https://${this.webpage.name}`});
    this.meta.updateTag({name:'og:title', content: item.metaTitle});
    this.meta.updateTag({name:'og:description', content: item.metaDescription});
    this.meta.updateTag({name:'og:image', content: `https://${this.webpage.name}/assets/images/${item.image}`}); // Recommend 1200×628

    this.meta.updateTag({name:'twitter:card', content: "summary_large_image"});
    this.meta.updateTag({name:'twitter:url', content: `https://${this.webpage.name}` });
    this.meta.updateTag({name:'twitter:title', content: item.metaTitle });
    this.meta.updateTag({name:'twitter:description', content: item.metaDescription});
    this.meta.updateTag({name:'twitter:image', content: `https://${this.webpage.name}/assets/images/${item.image}`});
  }

  transformFilename(input: string): string {
    const image = input.replace(/(\.(jpg|webp))$/, '-1x$1');
    return `assets/images/${image}`;
  }

}