import { Component, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { WebpageService } from '../../services/web.service';
import { PreloadService } from '../../services/preload.service';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-article',
  templateUrl: './article.page.html',
  styleUrls: ['./article.page.scss'],
})
export class ArticlePage {

  @ViewChildren('subheadingRef', { read: ElementRef }) subheadingRefs: QueryList<ElementRef> | any;

  searchbarResults$: Subscription | undefined;
  webpage: any;
  item: any;
  category: any;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  breadcrumbs: any[] = [];
  relatedItems: any;
  relatedCategories: any;
  product: any;
  productNames: any;
  locale: any;
  avatar: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private webpageService: WebpageService,
    private preloadService: PreloadService,
    public platform: Platform,
    private routeReuseStrategy: RouteReuseStrategy
  ) {

    this.locale = this.webpageService.locale;
    this.routeReuseStrategy.shouldReuseRoute = () => false;

    this.route.url.subscribe(async segments => {
      this.webpage = await this.webpageService.getWebpageData(this.locale);
      if (this.router.getCurrentNavigation()?.extras.state?.['item']) {
        let child = this.router.getCurrentNavigation()?.extras.state?.['item'];
        this.item = await this.webpageService.getItem(child.slug, `${this.locale}-articles`);
        this.avatar = `/assets/avatars/${this.item.author.slug}.webp`;
        this.item.img = `/assets/images/${this.item.image}`;
        this.preloadService.preloadImage(this.item.img);
        this.preloadService.preloadImage(this.avatar);

        if (this.item) {
          this.getRelatedItemsAndCategories();
          this.webpageService.setMetaTags(this.item);
          this.webpageService.initializeSearchbar();
          this.webpageService.clearSearchbarInput();
          this.product = await this.webpageService.getItem(this.item.products[0], `${this.locale}-products`);
          this.productNames = [ { name: this.product.name, path: this.product.paths.uk } ];
        }
        else {
          this.router.navigateByUrl('/');
        }
      }
      else {
        const itemSlug:any = this.route.snapshot.paramMap.get('itemSlug');
        this.item = await this.webpageService.getItem(itemSlug, `${this.locale}-articles`);
        this.avatar = `/assets/avatars/${this.item.author.slug}.webp`;
        this.item.img = `/assets/images/${this.item.image}`;
        this.preloadService.preloadImage(this.item.img);
        this.preloadService.preloadImage(this.avatar);
        if (this.item) {
          this.getRelatedItemsAndCategories();
          this.webpageService.setMetaTags(this.item);
          this.webpageService.initializeSearchbar();
          this.webpageService.clearSearchbarInput();
          this.product = await this.webpageService.getItem(this.item.products[0], `${this.locale}-products`);
          this.productNames = [ { name: this.product.name, path: this.product.paths.uk } ];
        }
        else {
          this.router.navigateByUrl('/');
        }
      }
    });
  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilename(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  scrollToElement(subheadingRefName: string) {
    const elementToScroll = this.subheadingRefs.find((ref:any) => ref.nativeElement.id === subheadingRefName);
    if (elementToScroll) {
      elementToScroll.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getRelatedItemsAndCategories() {
    this.relatedItems = this.item.relatedItems;
    this.relatedItems.forEach((item:any) => {
      item.img1x = this.webpageService.transformFilename(item.image);
    });
    this.relatedCategories = this.item.relatedCategories;
    this.relatedCategories.forEach((item:any) => {
      item.img1x = this.webpageService.transformFilename(item.image);
    });
  }

}
