import { Component, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss'],
  animations: [ ]
})
export class ShareButtonsComponent {

  @Input() webpageCDN: any;
  @Input() item: any;
  shareUrl: any;

  constructor(private renderer: Renderer2) { }

  setShareUrl() {
      let toEncodeUrl = this.webpageCDN + '/' + this.item.path;
      this.shareUrl = encodeURIComponent(toEncodeUrl);
  }

  openUrl(url:any) {
    const link = this.renderer.createElement('a');
    this.renderer.setAttribute(link, 'href', url);
    this.renderer.setAttribute(link, 'target', '_blank');
    link.click();
  }

  shareViaFacebook() {
    this.setShareUrl();
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.shareUrl};src=sdkpreparse`;
    this.openUrl(facebookUrl);
  }



  shareViaX() {
    this.setShareUrl();
    const twitterUrl = `https://x.com/intent/tweet?url=${this.shareUrl}`;
    this.openUrl(twitterUrl);
  }

  shareViaPinterest() {
    this.setShareUrl();
    let toEncodeImageUrl = this.webpageCDN + '/assets/images/' + this.item.image;
    let imageUrl = encodeURIComponent(toEncodeImageUrl);

    const pinterestUrl = `https://pinterest.com/pin/create/button/?url=${this.shareUrl}&media=${imageUrl}&method=button&description=${this.item.name}`;
    this.openUrl(pinterestUrl);
  }

  shareViaWhatsApp() {
    this.setShareUrl();
    const whatsAppUrl = `https://wa.me/?text=${this.shareUrl}`;
    this.openUrl(whatsAppUrl);
  }

  shareViaEmail() {
    this.setShareUrl();
    const emailUrl = `mailto:?subject=${this.item.name}&body=${this.shareUrl}`;

    const link = this.renderer.createElement('a');
    this.renderer.setAttribute(link, 'href', emailUrl);
    this.renderer.setStyle(link, 'display', 'none');
    this.renderer.appendChild(document.body, link);
    link.click();
    this.renderer.removeChild(document.body, link);
  }

}
