import { Component, Input } from '@angular/core';
import { TextService } from 'src/services/text.service';

@Component({
  selector: 'related-items',
  templateUrl: './related-items.component.html',
  styleUrls: ['./related-items.component.scss'],
  animations: [ ]
})
export class RelatedItemsComponent {

  @Input() relatedItems: any;

  constructor(public text: TextService) { }

}
