<ion-row class="header-space"></ion-row>
<ion-row>
  <ion-col class="ads" size="2"></ion-col>
  <ion-col size-md="12" size-lg="8" class="ion-no-padding">
    <div class="breadcrumbs-container">
      <span class="breadcrumbs" *ngFor="let breadcrumb of category?.breadcrumbs; let last = last">
        <a rel="nofollow" [routerLink]="'/' + breadcrumb?.path" [state]="{data: breadcrumb, breadcrumbs: breadcrumbs, breadcrumbsNav: true}" >
          <small>{{ breadcrumb?.name }}</small>
          <small *ngIf="!last"> > </small>
        </a>
      </span>
    </div>
    <h1 class="category-name">{{category?.name}}</h1>
  </ion-col>
  <ion-col class="ads" size="2"></ion-col>
</ion-row>

<ion-content>
  <ion-row>
    <ion-col class="ads" size="2"></ion-col>
    <ion-col size-md="12" size-lg="8">

      <ion-list *ngIf="searchbarInputLength === 0" role="list">
        <div *ngFor="let group of sortedProducts" role="listitem">
          <h2>{{ group }}</h2>
          <ul>
            <li *ngFor="let item of groupedProducts[group]">
            <a [routerLink]="'/' + item.path" [state]="{data: item}" [attr.aria-label]="text.home.ariaLabel + item?.name" role="button" tabindex="0" >
               <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> {{item.name}}
            </a>
          </li>
          </ul>
        </div>

        <ion-infinite-scroll threshold="40%" (ionInfinite)="loadMoreData($event)">
          <ion-infinite-scroll-content *ngIf="!showData" loadingSpinner="bubbles"></ion-infinite-scroll-content>
        </ion-infinite-scroll>
        <div class="description">{{category?.description}}</div>
      </ion-list>

      <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>
      <app-footer *ngIf="sortedProducts?.length > 0"></app-footer>
    </ion-col>
    <ion-col class="ads" size="2"></ion-col>
  </ion-row>
</ion-content>
