import { Component } from '@angular/core';
import { InfiniteScrollCustomEvent} from '@ionic/angular';
import { Meta, Title } from '@angular/platform-browser';
import { WebpageService } from '../../services/web.service';
import { PreloadService } from '../../services/preload.service';
import { TextService } from 'src/services/text.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage {

  webpage: any;
  searchbarResults$: Subscription | undefined;
  articlesHealth: any[] = [];
  articlesNutrition: any[] = [];
  articlesWellness: any[] = [];
  articlesNews: any[] = [];
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  locale: any;
  showData = false;

  constructor(
    private title: Title,
    private meta: Meta,
    private route: ActivatedRoute,
    private webpageService: WebpageService,
    private preloadService: PreloadService,
    public text: TextService
  ) {

    this.locale = this.webpageService.locale;

    this.route.url.subscribe(async segments => {

      this.webpage = await this.webpageService.getWebpageData(this.locale);
      this.setMetaTagsForMainPage();
      this.webpageService.initializeSearchbar();
      let mainpage = await this.webpageService.getItem(`${this.locale}-mainpage`, `mainpage`);
      //console.log(mainpage);
      this.articlesHealth = mainpage.health;
      this.articlesHealth.forEach((item: any) => {
         item.img1x = `assets/images/${item.image}`;
         this.preloadService.preloadImage(item.img1x);
      });
      this.articlesNutrition = mainpage.nutrition;
      this.articlesNutrition.forEach((item: any) => {
        item.img1x = `assets/images/${item.image}`;
        this.preloadService.preloadImage(item.img1x);
      });
      this.articlesWellness = mainpage.wellness;
      this.articlesWellness.forEach((item:any) => {
        item.img1x = `assets/images/${item.image}`;
      });
    })
  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilename(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }


  setMetaTagsForMainPage() {
    this.title.setTitle(this.webpage.metaTitle);
    this.meta.updateTag({name:'google-site-verification', content: 'zgGmKGfDSXh5x5ahEzu5o3QIcg6cgGkAB0EuVjEebhM' });
    this.meta.updateTag({name:'description', content: this.webpage.metaDescription});
    this.meta.updateTag({name:'keywords', content: this.webpage.metaKeywords});
    //Facebook
    this.meta.updateTag({name:'og:type', content: 'website'});
    this.meta.updateTag({name:'og:site_name', content: this.webpage.metaWebpage});
    this.meta.updateTag({name:'og:url', content: `https://${this.webpage.metaWebpage}` });
    this.meta.updateTag({name:'og:title', content: this.webpage.metaTitle });
    this.meta.updateTag({name:'og:description', content: this.webpage.metaDescription });
    this.meta.updateTag({name:'og:image', content: this.webpage.metaImage}); // Recommend 1200×628

    this.meta.updateTag({name:'twitter:card', content: "summary_large_image"});
    this.meta.updateTag({name:'twitter:url', content: `https://${this.webpage.metaWebpage}`});
    this.meta.updateTag({name:'twitter:title', content: this.webpage.metaTitle});
    this.meta.updateTag({name:'twitter:description', content: this.webpage.metaDescription});
    this.meta.updateTag({name:'twitter:image', content: this.webpage.metaImage300});
  }

  loadMoreData(ev:any) {
    if(!this.showData) {
      this.showData = true;
      (ev as InfiniteScrollCustomEvent).target.complete();
    }
  }

}
