
<ion-grid>
  <ion-row>
    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" offset-lg="2" class="ion-no-padding">
      <div class="top-header">
        <nav class="desktop-menu" *ngIf="!isMobile">
          <a [routerLink]="'/'"  [attr.aria-label]="text.topheader.arialabel" role="button" tabindex="0">
            <img class="desktop-logo" alt="Logo" src="assets/medilight-100.jpg" />
          </a>
          <div *ngFor="let category of rootCategories" routerDirection="root" (click)="openItem(category)" lines="none" detail="false" routerLinkActive="selected">
            {{ category.shortname}}
          </div>
        </nav>
        <nav class="mobile-menu" *ngIf="isMobile">
          <div *ngIf="webpageService.showSearchBar" class="mobile-searchbar-container">
            <ion-searchbar
            search-icon="null"
            [debounce]="500"
            placeholder="{{text.topheader.placeholderText}}"
            (ionInput)="searchItem($event)"
            (ionClear)="clearSearchbar()"
            value="{{searchbarInput}}">
            </ion-searchbar>
            <ion-icon [attr.aria-label]="text.topheader.ariaButtonCloseSearch" role="button" tabindex="0"
            class="close-searchbar-icon"
            name="search"
            src="assets/icons-custom/arrow-back-outline.svg"
            (click)="toggleSearch()">
            </ion-icon>
          </div>
          <div class="mobile-logo-container">
            <a [routerLink]="'/'"  [attr.aria-label]="text.topheader.arialabel" role="button" tabindex="0">
              <img class="mobile-logo" alt="Logo" src="assets/medilight-100.jpg" />
            </a>
          </div>
          <div class="title"> {{text.topheader.websiteName}} </div>
          <div class="menu-icons">
            <ion-button *ngIf="isMobile" fill="clear" class="search-icon" (click)="toggleSearch()" [attr.aria-label]="text.topheader.ariaButtonSearch" role="button" tabindex="0">
              <ion-icon src="assets/icons-custom/search-circle-outline.svg"></ion-icon>
            </ion-button>
            <ion-button *ngIf="isMobile" fill="clear" class="menu-icon" (click)="toggleMenu()" [attr.aria-label]="text.topheader.ariaButtonMenu" role="button" tabindex="0">
              <ion-icon src="assets/icons-custom/menu-outline.svg"></ion-icon>
            </ion-button>
          </div>
        </nav>
      </div>
      <ion-toolbar *ngIf="!isMobile">
        <ion-searchbar
        [debounce]="500"
        placeholder="{{text.topheader.placeholderText}}"
        (ionInput)="searchItem($event)"
        (ionClear)="clearSearchbar()"
        value="{{searchbarInput}}">
        </ion-searchbar>
      </ion-toolbar>
    </ion-col>
  </ion-row>

  <div class="side-menu-overlay" *ngIf="menuOpen" (click)="toggleMenu()"></div>
  <nav class="side-menu" [ngClass]="{'open': menuOpen}">
    <ion-content style="height: 100%;">
      <ion-list role="list" style="margin-bottom: 7vh">
        <ion-list-header role="listitem">
          <ion-label>Menu</ion-label>
          <ion-button fill="clear" class="close-side-menu-icon" (click)="toggleMenu()" [attr.aria-label]="text.topheader.ariaButtonCloseMenu" role="button" tabindex="0">
            <ion-icon src="assets/icons-custom/close-outline.svg"></ion-icon>
          </ion-button>
        </ion-list-header>
        <div role="listitem" *ngFor="let category of mobileMenuCategories; let i = index;" [ngStyle]="{'background': getColorForCategory(i)}">
          <a (click)="goTo($event, category.path)" role="link">
            <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> <span>{{ category.name }}</span>
          </a>
          <ul *ngIf="category.subcategories.length" role="list">
            <li (click)="goTo($event, subcategory.path)" role="listitem" *ngFor="let subcategory of category.subcategories" [ngStyle]="{'background': getColorForCategory(i)}" [attr.aria-label]="'Open ' + subcategory?.name">
                <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> {{subcategory.name}}
            </li>
          </ul>
        </div>
        <div role="listitem" class="menu-bottom-links">
          <ul role="list">
            <li role="listitem" (click)="goTo($event, 'contact')">
              <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon>  {{text.footer.contact}}
            </li>
            <li role="listitem" (click)="goTo($event, 'privacy-policy')">
              <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon>  {{text.footer.privacyPolicy}}
            </li>
            <li role="listitem">
              <a type="button" data-cc="show-preferencesModal">
                <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> Cookie preferences
              </a>
            </li>
          </ul>
        </div>
      </ion-list>
    </ion-content>
  </nav>
</ion-grid>
