import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { WebpageService } from '../../services/web.service';
import { TextService } from 'src/services/text.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { Platform } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.page.html',
  styleUrls: ['./contact.page.scss'],
})
export class ContactPage {

  webpage: any;
  searchbarResults$: Subscription | undefined;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  messageSuccess: any;
  messageError: any;
  locale: any;

  contactForm: FormGroup;

  constructor(
    private title: Title,
    private meta: Meta,
    private webpageService: WebpageService,
    public platform: Platform,
    public text: TextService,
    private formBuilder: FormBuilder
  ) {

    this.setMetaTags();
    this.webpageService.initializeSearchbar();

    this.contactForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      message: ['', Validators.compose([Validators.required])],
    });

  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilename(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  async onSubmitContactForm() {
    if (this.contactForm.valid) {
      this.messageSuccess = this.text.contact.messageSuccess;
      const response:any = await firstValueFrom(this.webpageService.sendMessageViaContactForm(this.contactForm.value));
      if (response.status === 'OK') {
      }
      else {
        this.messageError = this.text.contact.messageError;
      }
    }
  }

  setMetaTags() {
    this.title.setTitle(this.text.contact.pageTitle);
    this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
  }


}
