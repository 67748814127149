import { Component, OnInit, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { WebpageService } from '../../services/web.service';
import { TextService } from 'src/services/text.service';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss'],
  animations: [ ]
})
export class TopHeaderComponent implements OnInit, OnDestroy {

  rootCategories: any[] = [];
  searchbarInput: any = '';
  clearSearchText$: any;
  isDesktop: boolean;
  rootCategories$: any;
  locale: any;
  isMobile = true;
  menuOpen = false;
  searchOpen = false;
  mobileMenuCategories:any;

  constructor(
    private platform: Platform,
    private router: Router,
    public webpageService: WebpageService,
    public text: TextService) {
      this.isDesktop = this.platform.is('desktop');
      this.locale = this.webpageService.locale;
      //console.log('Width: ', this.platform.width());
        if (this.platform.width() >= 600) {
          this.isMobile = false;
          //console.log('isMobile: ', this.isMobile);
        }
    }

    transformMenuData(menuData: any)  {
      const categories:any = [];

      // Filter ROOT categories
      const rootCategories = menuData.filter((item:any) => item.categories === 'ROOT');

      // Map ROOT categories to our structure
      rootCategories.forEach((rootCategory:any) => {
        const category: any = {
          name: rootCategory.shortname,
          path: rootCategory.path,
          subcategories: []
        };

        // Find and add subcategories
        const subcategories = menuData.filter((item:any) => item.categories === category.name.toLowerCase());
        subcategories.forEach((subcategory:any) => {
          category.subcategories.push({ name: subcategory.shortname, path: subcategory.path });
        });

        categories.push(category);
      });

      return categories;
    }

  goTo(event: MouseEvent, path: string) {
    event.stopPropagation(); // Stop event propagation
    this.router.navigateByUrl(path); // Navigate to the specified path
    this.toggleMenu();
  }

  getColorForCategory(index:any) {
    if(index === 0) { return 'rgba(167, 199, 231, 0.2)'}
    if(index === 1) { return 'rgba(255, 127, 80, 0.2)'}
    if(index === 2) { return 'rgba(178, 240, 182, 0.2)'}
    if(index === 3) { return 'rgba(255, 192, 203, 0.2)'}
    else {
      return 'rgba(167, 199, 231, 0.2)';
    }
  }

  toggleMenu() { this.menuOpen = !this.menuOpen; }

  toggleSearch() { this.webpageService.showSearchBar = !this.webpageService.showSearchBar; }

  async ngOnInit() {
    this.clearSearchText$ = this.webpageService.clearSearchSubject.subscribe((clear:any) => {
      if (clear) {
        this.searchbarInput = '';
        this.webpageService.clearCompleted();
      }
    });
    const deviceWidth = this.platform.width();
    if (deviceWidth >= 600) {
      this.rootCategories = await this.webpageService.getItem(`${this.locale}-topmenu`, `topmenu`);
    }
    if(deviceWidth < 600) {
      const menuData = await this.webpageService.getItem(`${this.locale}-mobilemenu`, `mobilemenu`);
      this.mobileMenuCategories = this.transformMenuData(menuData);
    }

  }

  openItem(category:any) {
    const navigationExtras: NavigationExtras = { state: { category }};
    this.router.navigate(['/' + category.path], navigationExtras);
  }

  clearSearchbar() {
    if (!this.isDesktop) {
    // npm install @capacitor/keyboard
    // npx cap sync
    // this.keyboard.hide();
    }
  }

  searchItem(event: any) {
    this.searchbarInput = event.detail.value;
    this.webpageService.search(this.searchbarInput);
  }

  ngOnDestroy() { this.clearSearchText$.unsubscribe(); }

}
