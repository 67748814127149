import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras} from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { WebpageService } from '../../services/web.service';
import { Subscription } from 'rxjs';
import { InfiniteScrollCustomEvent} from '@ionic/angular';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.page.html',
  styleUrls: ['./articles-list.page.scss'],
})
export class ArticlesListPage {

  webpage:any;
  locale: any;
  searchbarResults$: Subscription | undefined;
  category: any;
  items: any[] = [];
  itemsPerPage = 12;
  maxItems: any;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  pageItems = 100;
  breadcrumbs: any[] = [];
  images = {};
  imageAppUrl: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private webpageService: WebpageService) {

    this.locale = this.webpageService.locale;

    this.route.url.subscribe(async segments => {
      this.webpage = await this.webpageService.getWebpageData(this.locale);
      if (segments.length === 1) {
        const segment:any = segments[0].path;
        this.category = await this.webpageService.getItem(segment, `${this.locale}-categories`);
        this.maxItems = this.category.children.length;
        this.items = this.category.children;
        this.items.forEach((item: any) => {
          item.img1x = this.webpageService.transformFilename(item.image);
        });

        this.setMetaTags();
        this.webpageService.initializeSearchbar();

      }
      else if (segments.length === 2) {
        const segment:any = segments[1].path;
        this.category = await this.webpageService.getItem(segment, `${this.locale}-categories`);
        this.maxItems = this.category.children.length;
        this.items = this.category.children;
        this.items.forEach((item: any) => {
          item.img1x = this.webpageService.transformFilename(item.image);
        });
        this.setMetaTags();
        this.webpageService.initializeSearchbar();
        if (this.category.inside === 'ARTICLES') {
          this.category.path = `${this.webpage.articleItemSlug}`;
        }
      }
      else {
        this.goToMainPage();
      }
    })
  }

  goToMainPage() { this.router.navigateByUrl('/'); }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilename(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  setMetaTags() {
    this.title.setTitle(this.category.metaTitle);
    this.meta.updateTag({name:'description', content: this.category.metaDescription});
    this.meta.updateTag({name:'keywords', content: this.category.keywords});
    //Facebook
    this.meta.updateTag({name:'og:type', content: 'website'});
    this.meta.updateTag({name:'og:site_name', content: this.webpage.name });
    this.meta.updateTag({name:'og:url', content: `https://${this.webpage.name}` });
    this.meta.updateTag({name:'og:title', content: this.category.metaTitle});
    this.meta.updateTag({name:'og:description', content: this.category.metaDescription});
    this.meta.updateTag({name:'og:image', content: `https://${this.webpage.name}/assets/images/${this.category.image}`}); // Recommend 1200×628

    this.meta.updateTag({name:'twitter:card', content: "summary_large_image"});
    this.meta.updateTag({name:'twitter:url', content: `https://${this.webpage.name}` });
    this.meta.updateTag({name:'twitter:title', content: this.category.metaTitle});
    this.meta.updateTag({name:'twitter:description', content: this.category.metaDescription});
    this.meta.updateTag({name:'twitter:image', content: `https://${this.webpage.name}/assets/images/${this.category.image}`});
  }

  loadMoreData(ev:any) {
    this.itemsPerPage = this.itemsPerPage + 12;
    setTimeout(() => {
      (ev as InfiniteScrollCustomEvent).target.complete();
      if (this.items.length > this.maxItems) {
        ev.target.disabled = true;
      }
    }, 500);
  }
}