<ion-row class="header-space"></ion-row>
<ion-row>
  <ion-col class="ads" size="2"></ion-col>
  <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" class="ion-no-padding">
    <div class="breadcrumbs-container">
      <span class="breadcrumbs" *ngFor="let breadcrumb of item?.breadcrumbs; let last = last">
        <a rel="nofollow" [routerLink]="'/' + breadcrumb?.path" [state]="{data: breadcrumb, breadcrumbs: breadcrumbs, breadcrumbsNav: true}" >
          <small>{{ breadcrumb?.name }}</small>
          <small *ngIf="!last"> > </small>
        </a>
      </span>
    </div>
  </ion-col>
  <ion-col class="ads" size="2"></ion-col>
</ion-row>

<ion-content>
  <ion-row>
    <ion-col class="ads" size="2"></ion-col>
    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" class="ion-no-padding">
      <article>
        <ion-list role="list" *ngIf="item?.title && searchbarInputLength === 0">
          <div role="listitem">
            <h1 *ngIf="item?.title">{{item.title}}</h1>
          </div>
          <div role="listitem" class="author-container">
            <div class="avatar-author">
              <ion-img alt="{{item.author.name}}" src="{{avatar}}"></ion-img>
              <div class="author-name">{{ item.author.name }}</div>
            </div>
            <time class="date-container" [attr.datetime]="item.createdAt | date:'yyyy-MM-ddTHH:mm:ss.sss'+'Z'">{{ item.createdAt | date:'MM/dd/yyyy' }}</time>
          </div>
          <div role="listitem" class="article-image-container">
            <ion-skeleton-text *ngIf="!item.loaded" [animated]="true"></ion-skeleton-text>
            <ion-img alt="{{item.imageAlt}}" src="{{item?.img}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
          </div>
          <div role="listitem">
            <share-buttons [webpageCDN]="webpage?.cdn" [item]="item"></share-buttons>
          </div>

          <div role="listitem" class="contents-container">
            <ng-container *ngFor="let section of item.sections; let i = index">
              <div class="contents" *ngIf="section.subheading" (click)="scrollToElement(section.subheadingRef)">
                <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> {{section.subheading}}
              </div>
              <div class="key-summary" *ngIf="section?.sectionSummary" [innerHTML]="section.sectionSummary"></div>
            </ng-container>
          </div>

          <div role="listitem" class="article-text" *ngIf="item?.introduction" [innerHTML]="item.introduction"></div>

          <ng-container *ngFor="let section of item.sections; let i = index">
            <div role="listitem" id="{{section.subheadingRef}}" #subheadingRef *ngIf="section.subheading">
              <h2>{{section.subheading}}</h2>
            </div>
            <div role="listitem" *ngIf="section.section && productNames" [innerHTML]="section.section | createProductLink:productNames"></div>
            <div role="listitem" *ngIf="section.interesting_fact" class="tip-box">
              <div class="heading">
                <ion-icon class="icon" src="assets/icons-custom/bulb.svg"></ion-icon>
                <h3>{{item?.subheadingFacts}}</h3>
              </div>
              <p>{{section.interesting_fact}}</p>
            </div>
            <!--<product-promo *ngIf="i === 0" [product]="product"></product-promo>-->
            <!--<banner-square *ngIf="i === 1" [therapy]="item.therapy"></banner-square>-->
            <!--<product-promo-reversed *ngIf="i === 2" [product]="product"></product-promo-reversed>-->
            <!--<banner-rectangle *ngIf="i === 3" [therapy]="item.therapy"></banner-rectangle>-->
            <!--<product-promo *ngIf="i === 4" [product]="product"></product-promo>-->
            <!--<product-promo-reversed *ngIf="i === 5" [product]="product"></product-promo-reversed>-->
          </ng-container>

          <div role="listitem" *ngIf="item?.subheadingSummary">
            <h2>{{item.subheadingSummary}}</h2>
          </div>
          <div *ngIf="item?.summary" [innerHTML]="item.summary"></div>

          <share-buttons role="listitem" [webpageCDN]="webpage?.cdn" [item]="item"></share-buttons>

          <related-items role="listitem" [relatedItems]="relatedItems" ></related-items>

          <related-categories role="listitem" [relatedCategories]="relatedCategories" ></related-categories>

        </ion-list>

       <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>
      </article>

      <app-footer *ngIf="item"></app-footer>

    </ion-col>
    <ion-col class="ads" size="2"> </ion-col>
  </ion-row>
</ion-content>
