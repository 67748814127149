<div class="share-buttons">
  <ion-button size="small" class="facebook" (click)="shareViaFacebook()">
    <ion-icon slot="start" src="{{'assets/icons-custom/facebook.svg'}}"></ion-icon>Facebook
  </ion-button>
  <ion-button size="small" class="pinterest" (click)="shareViaPinterest()">
    <ion-icon slot="start" src="{{'assets/icons-custom/pinterest.svg'}}"></ion-icon>Pinterest
  </ion-button>
  <ion-button size="small" class="whatsapp" (click)="shareViaWhatsApp()">
    <ion-icon slot="start" src="{{'assets/icons-custom/whatsapp.svg'}}"></ion-icon>WhatsApp
  </ion-button>
  <ion-button size="small" class="x-twitter" (click)="shareViaX()">
    <ion-icon slot="start" src="{{'assets/icons-custom/x.svg'}}"></ion-icon>X (Twitter)
  </ion-button>
  <ion-button size="small" class="email" (click)="shareViaEmail()">
    <ion-icon slot="start" src="{{'assets/icons-custom/email.svg'}}"></ion-icon>Email
  </ion-button>
</div>