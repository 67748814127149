import { Component, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import * as CookieConsent from 'vanilla-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit{

  isBrowser: any;

  constructor(@Inject(PLATFORM_ID) platformId: Object, @Inject(DOCUMENT) private document: Document) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {

    CookieConsent.run({
      // autoClearCookies: true,
      // autoShow: true,
      // disablePageInteraction: false,
      // hideFromBots: true,
      manageScriptTags: false,
      // revision: 0,
      // root: null,
      // mode: 'opt-in',

      cookie: {
        name: 'cc_cookies',
        // domain: location.hostname,
        // path: '/',
        // sameSite: "Lax",
        // expiresAfterDays: 365,
    },

      guiOptions: {
        consentModal: {
          layout: 'box wide',
          position: 'bottom center',
          equalWeightButtons: true,
          flipButtons: true,
        },
        preferencesModal: {
          layout: 'box',
          equalWeightButtons: true,
          flipButtons: true
        },
      },

      onFirstConsent: ({ cookie }) => { },

      onConsent: ({ cookie }) => { },

      onChange: ({ cookie, changedCategories, changedServices }) => {
        // console.log('onChange fired', changedCategories, changedServices);
      },

      onModalShow: ({ modalName }) => {
       // console.log(modalName, 'visible');
      },

      onModalHide: ({ modalName }) => {
       // console.log(modalName, 'hidden');
      },

      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {
          enabled: true,
          readOnly: false,
          autoClear: {
            cookies: [{ name: /^(_ga|_gid)/ }],
          },
          services: {
            ga4: {
              label: 'Google Analytics',
              onAccept: () => { },
              onReject: () => { },
            }
          },
        },
        ads: {
          enabled: true,
          readOnly: false,
          services: {
            awin: {
              label: 'AWin',
              onAccept: () => { },
              onReject: () => { },
            }
          },
        },
      },

      language: {
        default: 'en',
        // autoDetect: "browser"
        translations: {
          en: {
            consentModal: {
              title: 'Welcome!',
              description: 'We use cookies to personalise content and ads and to analyse our traffic. We also share information about your use of our site with our  advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.',
              acceptAllBtn: 'Accept',
              acceptNecessaryBtn: 'Reject',
              showPreferencesBtn: 'Manage preferences',
            },
            preferencesModal: {
              title: 'CookieConsent Center',
              acceptAllBtn: 'Accept',
              acceptNecessaryBtn: 'Reject',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              serviceCounterLabel: 'Service|Services',
              sections: [
                {
                  title: 'Cookie Usage',
                  description: 'We use cookies to personalise content and ads and to analyse our traffic. We also share information about your use of our site with our  advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.',
                },
                {
                  title: 'Strictly Necessary Cookies <span class="pm__badge">always enabled</span>',
                  description: 'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Performance & Analytics Cookies',
                  description: 'Performance & Analytics Cookies track how users interact with a website, helping improve its performance and user experience. They provide insights into traffic and user behavior for optimization purposes.',
                  linkedCategory: 'analytics',
                },
                {
                  title: 'Advertisement Cookies',
                  description: 'Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
                  linkedCategory: 'ads',
                },
                {
                  title: 'Last updated on 5/8/2024',
                },
              ],
            },
          },
        },
      },
    });
  }
  }

}
