<div class="h2-container">
  <h2 class="related-categories-heading">{{text.relatedCategories.subheading}}</h2>
</div>
<ion-row>
    <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="3" *ngFor="let relatedItem of relatedCategories" role="list">
      <div role="listitem" class="item-container default-zoom-color zoom-effect">
        <a class="related-item-image-container" [routerLink]="'/' + relatedItem.path" [state]="{data: relatedItem}"  [attr.aria-label]="text.relatedCategories.ariaLabel + relatedItem?.name" tabindex="0" role="button">
          <ion-skeleton-text [ngClass]="{'hidden': relatedItem?.loaded}" [animated]="true"></ion-skeleton-text>
          <ion-img role="img" style="padding: 0.5vh" alt="{{relatedItem?.name}}" src="{{relatedItem?.img1x}}" [ngClass]="{'fadeIn': relatedItem.loaded}" (ionImgDidLoad)="relatedItem.loaded = true"></ion-img>
        </a>
        <span class="related-item-name">{{relatedItem.name}}</span>
      </div>
    </ion-col>
</ion-row>