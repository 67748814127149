import { Component, Input } from '@angular/core';
import { TextService } from 'src/services/text.service';


@Component({
  selector: 'related-categories',
  templateUrl: './related-categories.component.html',
  styleUrls: ['./related-categories.component.scss'],
  animations: [ ]
})
export class RelatedCategoriesComponent {

  @Input() relatedCategories: any;

  constructor(public text: TextService) { }

}
