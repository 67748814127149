import { Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import { WebpageService } from 'src/services/web.service';
import { Platform } from '@ionic/angular';


@Component({
  selector: 'searchbar-items-list',
  templateUrl: './searchbar-items-list.component.html',
  styleUrls: ['./searchbar-items-list.component.scss'],
  animations: [ ]
})
export class SearchbarItemsListComponent {

  @Input() searchbarItems: any[] = [];
  @Input() searchbarInputLength: number = 0;
  listClass:any;

  constructor(private webpageService: WebpageService, private platform: Platform) {
    if (this.platform.is('mobile')) {
      // console.log('Is mobile: ', this.platform.is('mobile'));
      this.listClass = 'mobile-list';
    } else {
      // Default class for web and other platforms
      this.listClass = 'desktop-list';
    }
  }

  async openSearchbarItem(item: any) { await this.webpageService.openSearchbarItem(item); }




}
